import styled from "styled-components";

interface QuestionAnswerContainerProps {
  noContext?: boolean;
}
export const QuestionAnswerContainer = styled.li<QuestionAnswerContainerProps>`
  width: ${(props) => (props.noContext ? "40%" : "90%")};
  margin: 0.5em;
`;

export const AnswerHiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 10px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &:focus:not(:focus-visible) + label {
    outline: none;
    box-shadow: inherit;
  } 
  &:focus-visible + label {
    outline: none;
    box-shadow: 0px 0px 0px 0.5em ${({ theme }) => (theme.focusColor ? theme.focusColor : theme.primaryColor)};
  }
`;

interface AnswerCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  showResult?: boolean;
  isValid: boolean;
}
export const AnswerCheckbox = styled.label<AnswerCheckboxProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms;
  cursor: pointer;
  color: ${({ theme }) => theme.textAnswerColor};
  background-color: #fff;
  border-style: solid;
  border-color: ${({ theme }) => theme.secondaryColor || "#E3E3E3"};
  border-width: 1px;
  padding: 2em;
  font-weight: bold;

  :hover {
    -webkit-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.1);
  }
  ${({ checked, theme }) =>
    checked &&
    `
      z-index: 2;
      border-left-width: 0.25em;
      border-left-color: ${theme.secondaryColor || theme.primaryColor};
      -webkit-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.1);
    `}
  ${({ showResult, isValid, theme }) =>
    showResult && isValid && (theme.validColor === undefined ? "color: #0d9353;" : `color: ${theme.validColor};`)}
  ${({ disabled }) => disabled && "pointer-events: none;"}
  ${({ showResult, isValid }) => showResult && !isValid && "font-weight: normal;"}
  .answer-result-icon {
    position: absolute;
    top: -0.2em;
    right: -0.2em;
    background-color: white;
    border-radius: 50%;
  }
`;

export const AnswerCheckboxPill = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: ${(props) => props.theme.secondaryColor || props.theme.primaryColor};
  left: -0.65em;
`;

export const AccessibleVerboseStatus = styled.p`
position:absolute;
opacity:0;
`;
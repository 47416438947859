import React from "react";
import IconFeedbackCorrect from "../assets/IconFeedbackCorrect";
import IconFeedbackIncorrect from "../assets/IconFeedbackIncorrect";
import MediaBox from "../MediaBox/MediaBox.component";
import { AppText, Feedback } from "../types";
import {
  AccessibleVerboseStatus,
  FeedbackContainer,
  FeedbackContent,
  FeedbackDescriptionContainer,
  FeedbackMediaContainer,
  FeedbackTitle,
} from "./Feedback.style";

interface FeedbackWithHtml extends Feedback {
  htmlContent: { __html: string };
}

interface Props {
  feedback: FeedbackWithHtml;
  isAnswerCorrect: boolean;
  showResult: boolean;
  text?: AppText;
}

const FeedbackView: React.FC<Props> = (props) => {
  return (
    <FeedbackContainer
      tabIndex={-1}
      className={`feedback-container ${props.isAnswerCorrect ? "correct" : "not-correct"}`}
      id={"feedback-container"}
    >
      {props.showResult && props.isAnswerCorrect && (
        <React.Fragment>
          <IconFeedbackCorrect className={"feedback-icon"} />
          <AccessibleVerboseStatus role="status">{props.text?.correct || "correct"}</AccessibleVerboseStatus>
        </React.Fragment>
      )}
      {props.showResult && !props.isAnswerCorrect && (
        <React.Fragment>
          <IconFeedbackIncorrect className={"feedback-icon"} />
          <AccessibleVerboseStatus role="status">{props.text?.correct || "incorrect"}</AccessibleVerboseStatus>
        </React.Fragment>
      )}
      <FeedbackTitle className="feedback-title">{props.feedback.title}</FeedbackTitle>
      <FeedbackContent className="feedback-content">
        {props.feedback.media && (
          <FeedbackMediaContainer className="feedback-media-container">
            <MediaBox media={props.feedback.media} />
          </FeedbackMediaContainer>
        )}
        <FeedbackDescriptionContainer className="feedback-description-container">
          <p className="feedback-description" dangerouslySetInnerHTML={props.feedback.htmlContent} />
        </FeedbackDescriptionContainer>
      </FeedbackContent>
    </FeedbackContainer>
  );
};

export default FeedbackView;

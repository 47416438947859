import React, { useState } from "react";
import { Question, Answer, AppText, AnswerResult } from "../types";
import CellCheckbox from "../Answer/CellCheckbox.component";
import Feedback from "../Feedback/Feedback.component";
import MediaBox from "../MediaBox/MediaBox.component";
import {
  QuestionContainer,
  QuestionInstructions,
  QuestionContent,
  QuestionAnswersContainer,
  QuestionButtonValidate,
  QuestionMediaContainer,
  QuestionTitle,
  QuestionColumn,
  QuestionContextTitle,
  QuestionContextDescription,
  Footer,
} from "./Question.style";
import { isQuestionValid } from "../../utils";

interface Props {
  question: Question;
  onAnswerQuestion: (result: AnswerResult) => void;
  text?: AppText;
  hideNext?: boolean;
  nextQuestion: () => void;
}

const QuestionView: React.FC<Props> = (props) => {
  const [answers, setAnswers] = useState<Answer[]>(props.question.answerList);
  const [isShowingFeedback, setShowFeedback] = useState(false);
  const [isShowingResult, setShowResult] = useState(false);
  const [hasBeenAnswered, setHasBeenAnswered] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);

  const displayFeedback = () => {
    setShowFeedback(true);
    setTimeout(() => {
      const feedbackContainer = document.getElementById("feedback-container");
      if (feedbackContainer) window.scrollTo({ left: 0, top: feedbackContainer.offsetTop, behavior: "smooth" });
    }, 300);
  };

  const shouldDisplayButton = () => {
    if (props.hideNext && hasBeenAnswered) return false;
    return true;
  };

  const onValidate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsAnswerCorrect(
      isQuestionValid({
        ...props.question,
        answerList: answers.map((answer) => ({ ...answer, selected: Boolean(answer.selected) })),
      }),
    );
    if (!hasBeenAnswered) {
      setHasBeenAnswered(true);
      props.onAnswerQuestion({
        idAnswers: answers.filter((answer) => answer.selected).map((answer) => answer.id),
        idQuestion: props.question.id,
      });
      if (!props.question.showEachAnswerResults && !props.question.feedback) return props.nextQuestion();

      if (props.question.showEachAnswerResults) {
        setShowResult(true);
        return props.question.feedback && !isShowingFeedback && displayFeedback();
      }

      if (!props.question.showEachAnswerResults && props.question.feedback) return displayFeedback();
    } else {
      if (!props.question.feedback || isShowingFeedback) return props.nextQuestion();
    }
  };

  const onChangeQCUAnswer = (answer: Answer) => {
    setAnswers(
      answers.map((item) => {
        if (item.selected) item.selected = false; // On enlève ceux qui sont cochés
        if (item.id === answer.id) item.selected = !item.selected; // On toggle l'item
        return item;
      }),
    );
  };

  const onChangeQCMAnswer = (answer: Answer) => {
    setAnswers(answers.map((item) => (item.id === answer.id ? { ...item, selected: !item.selected } : item)));
  };

  const onChangeAnswer = (answer: Answer) => {
    props.question.type === "singleAnswer" ? onChangeQCUAnswer(answer) : onChangeQCMAnswer(answer);
  };

  return (
    <QuestionContainer className={"question-internal-container"}>
      {!(isShowingFeedback && props.question.feedback?.display === "replace") && (
        <QuestionContent className="question-content">
          {props.question.context && (
            <QuestionColumn noMedia={props.question.context.media === undefined} className={"question-column context"}>
              <QuestionContextTitle className="question-context-title">
                {props.question.context.title}
              </QuestionContextTitle>
              <QuestionContextDescription className="question-context-description">
                {props.question.context.description}
              </QuestionContextDescription>

              {props.question.context.media && (
                <QuestionMediaContainer className="question-media-container">
                  <MediaBox media={props.question.context.media} />
                </QuestionMediaContainer>
              )}
            </QuestionColumn>
          )}

          <QuestionColumn
            className={`question-column question ${props.question.context === undefined ? "no-context" : ""}`}
            noContext={props.question.context === undefined}
          >
            <QuestionTitle className="question-title">{props.question.question}</QuestionTitle>
            <QuestionInstructions className="question-instructions">{props.question.instructions}</QuestionInstructions>
            <QuestionAnswersContainer
              noContext={props.question.context === undefined}
              className="question-answers-container"
            >
              {answers.map((answer) => (
                <CellCheckbox
                  noContext={props.question.context === undefined}
                  key={answer.id}
                  onChange={onChangeAnswer}
                  answer={answer}
                  disabled={isShowingFeedback || isShowingResult}
                  showResult={isShowingResult}
                  text={props.text}
                />
              ))}
            </QuestionAnswersContainer>
          </QuestionColumn>
        </QuestionContent>
      )}
      <Footer className={"question-footer"}>
        {isShowingFeedback && props.question.feedback?.description && (
          <Feedback
            isAnswerCorrect={isAnswerCorrect}
            showResult={isShowingResult}
            feedback={{
              ...props.question.feedback,
              htmlContent: { __html: props.question.feedback.description || "" },
            }}
            text={props.text}
          />
        )}
        {shouldDisplayButton() && (
          <div className={"validate-btn-container"}>
            <QuestionButtonValidate
              id="question-validate-button"
              className={`question-button-validate ${isShowingFeedback ? "feedback" : ""}`}
              onClick={onValidate}
              disabled={answers.filter((answer) => answer.selected).length === 0}
            >
              {(props.question.showEachAnswerResults && !isShowingResult) ||
              (props.question.showEachAnswerResults && !isShowingResult)
                ? props.text?.validate || "VALIDER"
                : props.text?.next || "SUIVANT"}
            </QuestionButtonValidate>
          </div>
        )}
      </Footer>
    </QuestionContainer>
  );
};

export default QuestionView;

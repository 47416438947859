import React from "react";

const IconFeedbackIncorrect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="43"
    height="44"
    viewBox="0 0 43 44"
    {...props}
  >
    <defs>
      <path id="3ir4e0bqqa" d="M0 0L42.871 0 42.871 42.575 0 42.575z" />
      <path
        id="kn0s0lumyc"
        d="M27.782 14.658L21.59 20.849 15.399 14.658 14.273 15.784 20.465 21.975 14.273 28.166 15.399 29.292 21.59 23.101 27.782 29.292 28.907 28.166 22.716 21.975 28.907 15.784 27.782 14.658z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-230.000000, -845.000000) translate(81.000000, 108.000000) translate(149.000000, 737.000000) translate(0.000025, 0.081778)">
              <g>
                <mask id="2f80nt3jmb" fill="#fff">
                  <use xlinkHref="#3ir4e0bqqa" />
                </mask>
                <path
                  fill="#DF333F"
                  d="M41.613 23.739c.1-.826 1.258-1.6 1.258-2.452 0-.851-1.158-1.626-1.258-2.45-.1-.844.839-1.871.639-2.68-.202-.824-1.51-1.297-1.807-2.079-.3-.79.364-2.013-.026-2.754-.393-.746-1.778-.893-2.254-1.583-.48-.693-.128-2.039-.685-2.667-.558-.63-1.937-.443-2.567-1-.629-.558-.607-1.95-1.3-2.43-.69-.476-1.984.035-2.73-.358-.743-.388-1.055-1.746-1.844-2.047-.782-.295-1.915.512-2.738.31-.81-.199-1.438-1.441-2.282-1.542-.826-.1-1.732.956-2.584.956-.852 0-1.758-1.055-2.583-.956-.844.1-1.473 1.343-2.282 1.542-.823.202-1.956-.605-2.738-.31-.79.3-1.102 1.657-1.844 2.047-.746.393-2.04-.119-2.73.359-.693.479-.672 1.871-1.3 2.429-.63.557-2.01.37-2.567.998-.558.63-.205 1.976-.684 2.67-.478.689-1.863.834-2.256 1.582-.39.74.274 1.964-.025 2.754-.297.781-1.605 1.255-1.807 2.078-.2.81.74 1.837.638 2.68-.1.825-1.257 1.6-1.257 2.451 0 .852 1.157 1.626 1.257 2.452.101.843-.838 1.87-.638 2.68.202.823 1.51 1.296 1.807 2.078.3.79-.365 2.013.025 2.754.393.746 1.778.893 2.255 1.583.48.693.127 2.039.685 2.667.558.63 1.937.442 2.567 1 .628.558.606 1.95 1.3 2.429.689.476 1.984-.035 2.73.359.742.388 1.054 1.746 1.844 2.047.782.295 1.915-.512 2.738-.31.81.198 1.438 1.441 2.282 1.542.825.1 1.731-.956 2.583-.956.852 0 1.758 1.055 2.584.956.844-.1 1.472-1.344 2.282-1.542.823-.202 1.956.605 2.738.31.79-.3 1.101-1.657 1.843-2.047.747-.394 2.04.118 2.731-.36.692-.478.671-1.87 1.3-2.428.63-.558 2.009-.37 2.567-.999.557-.628.205-1.975.684-2.668.477-.69 1.862-.836 2.255-1.583.39-.74-.274-1.964.026-2.754.296-.781 1.605-1.255 1.807-2.078.2-.81-.74-1.837-.639-2.68"
                  mask="url(#2f80nt3jmb)"
                />
              </g>
              <path
                fill="#FFF"
                d="M39.243 21.287c0 9.835-7.973 17.808-17.808 17.808S3.628 31.122 3.628 21.287 11.6 3.48 21.435 3.48c9.835 0 17.808 7.972 17.808 17.807"
              />
              <path
                fill="#DF333F"
                d="M38.153 21.677c0 9.054-7.34 16.394-16.394 16.394-9.054 0-16.393-7.34-16.393-16.394 0-9.053 7.34-16.393 16.393-16.393 9.054 0 16.394 7.34 16.394 16.393"
              />
              <path
                fill="#CB121F"
                d="M6.475 25.133c0-9.878 7.656-17.886 17.1-17.886 4.14 0 7.936 1.539 10.894 4.099-2.995-3.922-7.718-6.452-13.034-6.452-9.054 0-16.393 7.34-16.393 16.393 0 2.675.64 5.2 1.777 7.43-.225-1.158-.344-2.356-.344-3.584"
              />
              <use fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeLinejoin="round" xlinkHref="#kn0s0lumyc" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconFeedbackIncorrect;

import React from "react";

const IconInvalid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Calque 1"
    viewBox="0 0 18 18"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0Zm4.05,11.64a1,1,0,0,1,0,1.41,1,1,0,0,1-1.41,0L9,10.41,6.36,13.05a1,1,0,0,1-.71.3,1,1,0,0,1-.71-1.71L7.58,9,4.94,6.36A1,1,0,0,1,6.36,4.94L9,7.58l2.64-2.64a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42L10.41,9Z"
      fill="curentColor"
    />
  </svg>
);

export default IconInvalid;

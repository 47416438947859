import React from "react";
import { Media } from "../types";
import { MediaImage, MediaVideo } from "./MediaBox.style";

interface Props {
  media: Media;
}

const getVideoType = (url: string) => {
  const types = [
    {
      name: "mp4",
      regex: "/*.mp4"
    },
    {
      name: "webm",
      regex: "/*.webm"
    },
    {
      name: "ogg",
      regex: "/*.ogv"
    }
  ];
  for (let i = 0, n = types.length; i < n; i++) {
    if (RegExp(types[i].regex).test(url)) return types[i].name;
  }
};

const mediaView = (media: Media) => {
  if (media.type === "image") {
    return <MediaImage className="media-image" src={media.url} />;
  } else if (media.type === "video") {
    return (
      <MediaVideo controls className="media-video">
        <source src={media.url} type={`video/${getVideoType(media.url)}`} />
        {media.subtitleUrl && (
          <track src={media.subtitleUrl} default kind="captions" />
        )}
      </MediaVideo>
    );
  } else return <p className="media-not-supported">No media</p>;
};

const MediaBox: React.FC<Props> = props => {
  return mediaView(props.media);
};

export default MediaBox;

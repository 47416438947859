import React, { useState } from "react";
import { AnswerResult, Theme, QuestionAggregate, AppText } from "../types";
import QuestionView from "../Question/Question.component";
import { QuizContainer } from "./Quiz.style";
import { ThemeProvider } from "styled-components";
import { theme } from "../theme";
import { isLastQuestion } from "../../utils";

interface Props {
  theme: Theme;
  questionAggregate: QuestionAggregate;
  onAnswerQuestion: (result: AnswerResult) => void;
  onNextQuestion?: (currentIndex: number) => void;
  startingIndex?: number;
  text?: AppText;
}

const QuizView: React.FC<Props> = (props) => {
  const questionList = props.questionAggregate.questionList;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(props.startingIndex || 0);

  const onAnswerQuestion = (result: { idQuestion: string; idAnswers: string[] }) => {
    props.onAnswerQuestion({
      idQuestion: result.idQuestion,
      idAnswers: result.idAnswers,
    });
  };
  const nextQuestion = () => {
    if (props.onNextQuestion) props.onNextQuestion(currentQuestionIndex);
    if (currentQuestionIndex < questionList.length - 1) {
      return setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
  return (
    <ThemeProvider theme={{ ...theme, ...props.theme }}>
      <QuizContainer className="quiz-container">
        <QuestionView
          key={questionList[currentQuestionIndex].id}
          question={questionList[currentQuestionIndex]}
          onAnswerQuestion={onAnswerQuestion}
          nextQuestion={nextQuestion}
          text={props.text}
          hideNext={
            isLastQuestion(props.questionAggregate, questionList[currentQuestionIndex].id) &&
            props.questionAggregate.hideNextButtonOnLastQuestion
          }
        />
      </QuizContainer>
    </ThemeProvider>
  );
};

export default QuizView;

import styled from "styled-components";

export const MediaVideo = styled.video`
  width: 100%;
  max-height: 100%;
`;

export const MediaImage = styled.img`
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
import { Question, QuestionAggregate } from "./components/types";

export const findItemAndIndexById = <T extends { id: string }>(
  id: string,
  tabWithId: T[],
): {
  item: T;
  index: number;
} => {
  let itemIndex = -1;
  const item = tabWithId.find((item, index) => {
    itemIndex = index;
    return item.id === id;
  });
  if (item) {
    return {
      item,
      index: itemIndex,
    };
  } else throw new Error(`FindItemAndIndexById not found`);
};

export const getQuestion = (aggregate: QuestionAggregate, idQuestion: string) => {
  return findItemAndIndexById(idQuestion, aggregate.questionList);
};

export const isQuestionValid = (question: Question) => {
  for (let i = 0, n = question.answerList.length; i < n; i++) {
    const answer = question.answerList[i];
    if (answer.valid !== answer.selected) return false;
  }
  return true;
};

export const isLastQuestion = (aggregate: QuestionAggregate, idQuestion: string) => {
  const questionSearch = getQuestion(aggregate, idQuestion);
  if (questionSearch.index === aggregate.questionList.length - 1) return true;
  return false;
};

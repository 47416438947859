import styled from "styled-components";

const devices = { mobile: "(max-width: 768px)" };

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 1em;
  position: relative;

  .feedback-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const FeedbackTitle = styled.h2`
  color: ${({ theme }) => theme.textColor};
`;

export const FeedbackContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  min-height: 100%;
`;

export const FeedbackMediaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  overflow: hidden;
  padding: 1em;
  max-width: 45%;
  @media ${devices.mobile} {
    max-width: 100%;
  }
`;

export const FeedbackDescriptionContainer = styled.div`
  position: relative;
  display: flex;
  height: auto;
  padding: 1em;
  color: ${({ theme }) => theme.textColor};
  white-space: pre-line;
  max-width: 45%;
  @media ${devices.mobile} {
    max-width: 100%;
  }
`;
export const AccessibleVerboseStatus = styled.p`
position:absolute;
opacity:0;
`;
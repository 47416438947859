import React, { useContext } from "react";
import { Answer, AppText } from "../types";
import IconValid from "../assets/IconValid";
import IconInvalid from "../assets/IconInvalid";
import {
  QuestionAnswerContainer,
  AnswerHiddenCheckbox,
  AnswerCheckbox,
  AnswerCheckboxPill,
  AccessibleVerboseStatus,
} from "./CellCheckbox.style";
import { ThemeContext } from "styled-components";

interface Props {
  noContext?: boolean;
  answer: Answer;
  disabled?: boolean;
  showResult?: boolean;
  onChange: (answer: Answer) => void;
  text?: AppText;
}

const CellCheckbox: React.FC<Props> = (props) => {
  const theme = useContext(ThemeContext);
  const onChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.showResult || props.onChange(props.answer);
  };

  const containerClassNames = [
    "answer-container",
    props.answer.selected ? "answer-container-checked" : "",
    props.showResult ? (props.answer.valid ? "answer-container-good-answer" : "answer-container-wrong-answer") : "",
  ];

  return (
    <QuestionAnswerContainer
      className={containerClassNames.filter((name) => name).join(" ")}
      noContext={props.noContext}
    >
      <AnswerHiddenCheckbox
        id={props.answer.id}
        type="checkbox"
        className="answer-hidden-checkbox"
        onClick={onChange}
      />
      <AnswerCheckbox
        checked={props.answer.selected}
        disabled={props.disabled}
        showResult={props.showResult}
        isValid={props.answer.valid}
        className="answer-checkbox"
        htmlFor={props.answer.id}
      >
        {props.answer.selected && <AnswerCheckboxPill className="answer-checkbox-pill" />}
        {props.answer.text}
        {props.showResult && props.answer.valid && (
          <React.Fragment>
            <AccessibleVerboseStatus>{props.text?.correct || "correct"}</AccessibleVerboseStatus>
            <IconValid className={"answer-result-icon valid"} fill={theme.validColor || "#0D9353"} />
          </React.Fragment>
        )}
        {props.showResult && !props.answer.valid && (
          <React.Fragment>
            <AccessibleVerboseStatus>{props.text?.correct || "incorrect"}</AccessibleVerboseStatus>
            <IconInvalid className="answer-result-icon invalid" fill={theme.invalidColor || "#DD3E3E"} />
          </React.Fragment>
        )}
      </AnswerCheckbox>
    </QuestionAnswerContainer>
  );
};

export default CellCheckbox;

import React from "react";

const IconValid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Calque 1"
    viewBox="0 0 18 18"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M9 0a9 9 0 109 9 9 9 0 00-9-9zm4.12 6.3L8.48 12a1 1 0 01-.72.37A1 1 0 017 12l-2.06-2a1 1 0 111.41-1.43l1.28 1.28L11.57 5a1 1 0 111.55 1.3z"
      fill="curentColor"
    />
  </svg>
);

export default IconValid;
